import breadcrumbComp from "../../common/breadcrumb-comp";
import TrapService from "./trap-manager-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import Utility from "../../../shared/utility";
import trapManagerService from "./trap-manager-service";
import systemSettingsService from "./../../../components/system-settings/js/system-settings-service";
import commonApiService from "../../common/js/common-api-service";
import DisplayTextData from "../../common/display-text-data.vue";
import FilterComp from "../../common/filter-comp.vue";

export default {
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    trapmanagerShow: false,
    searchdisable: false,
    trapDetailsArray: [],
    addselectedProject: "",
    editSelectedBusiness: "",
    addselectedTrap: "",
    searchFilter: "",
    actionList: [],
    uniqueTrapType: [],
    addBusinessList: [],
    showFilter: false,
    mainPage: "",
    favouriteList: [],
    businessList: [],
    disableBusiness: true,
    addMaster: true,
    editMaster: true,
    emailDropdownList: [],
    emailEditDropdownList: [],
    email: "",
    newEmail: "",
    newProcessEmail: "",
    emailUpdate: "",
    pageUrl: "",
    subPage: "",
    showAdd: false,
    trap_id: 0,
    subPageUrl: "",
    allowedEdit: false,
    backSlash: true,
    allowedit: false,
    disableSearch: true,
    disableEmails: false,
    disableComments: false,
    disableTraps: false,
    disableStatus: false,
    disableTrap: false,
    disableproject: false,
    disablehits: false,
    candelete: false,
    deleteDialog: false,
    backSlashParent: true,
    showRefreshDialog: true,
    refresh: true,
    isEditing: true,
    isFormValid: false,
    addTrapDialog: false,
    trapManSearch: "",
    selectedProject: 0,
    projectList: [],
    arrayData: [],
    selectedTrap: "",
    trapManList: [],
    trapManageList: [],
    exportDisable: true,
    trapManagerEdit: false,
    totalRecords: 0,
    deleteTrapDialog: false,
    addTrap: "",
    editTrap: "",
    addComments: "",
    addAPIEmailParam: "",
    editAPIEmailParam: "",
    addAPINewEmailParam: "",
    selectedBusiness: "",
    editAPINewEmailParam: "",
    required: [(v) => !!v || "Field is required"],
    projSelec: true,
    subProcessChangeTriggered: false,
    errorSubProcessMessage: "",
    addEmailProcessMapperDialog: false,
    disableAddBusiness: true,
    showData: false,
    processList: [],
    selectedProcess: 0,
    emailProcessList: [],
    tempTrapList: [],
    trapHistory: false,
    trapHistoryDialog: false,
    trapHistoryList: [],
    excelName: "",
    trapHeaders: [
      {
        text: "Order Type",
        value: "Order Type",
        width: "15%",
      },
      {
        text: "Order",
        value: "Order",
        width: "15%",
      },
      {
        text: "RMA",
        value: "RMA",
        width: "15%",
      },
      {
        text: "BCN",
        value: "BCN",
        width: "15%",
      },
      {
        text: "Serial Number",
        value: "Serial Number",
        width: "15%",
      },
      {
        text: "Waybill",
        value: "Waybill",
        width: "15%",
      },
      {
        text: "Received",
        value: "Received",
        width: "20%",
      },
      {
        text: "Received By",
        value: "ReceivedBy",
        width: "20%",
      },
      {
        text: "Alert Sent",
        value: "AlertSent",
        width: "20%",
      },
    ],
    headers: [
      {
        text: "Project",
        align: "start",
        value: "Project",
        class: "primary customwhite--text",
        width: "20%",
      },
      {
        text: "Business",
        value: "business",
        class: "primary customwhite--text",
        width: "20%",
      },
      {
        text: "Trap Type",
        value: "TrapType",
        class: "primary customwhite--text",
        width: "20%",
      },
      {
        text: "Trap",
        value: "trap",
        class: "primary customwhite--text",
        width: "20%",
      },
      {
        text: "Hits",
        value: "Hits",
        class: "primary customwhite--text",
        width: "10%",
      },

      {
        text: "Status",
        value: "IsActive",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
        width: "5%",
      },
    ],
    selectedStatus: "",
    statusselected: "",
    statusList: [
      {
        active: "",
        value: "",
      },
      {
        active: "Yes",
        value: "Yes",
      },
      {
        active: "No",
        value: "No",
      },
    ],
    json_fields: {
      Project: "Project",
      "Trap Type": "TrapType",
      Trap: "trap",
      Hits: "Hits",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },

  mounted() {
    this.getProjectDetails();
    this.getTrapTypeDetails();
    this.getDataPageLoad();
    this.emailData();
  },
  methods: {
    async getDataPageLoad() {
      let processData = await systemSettingsService.getEmailProcessDropdown("get");
      if (processData !== null && processData !== undefined && processData != {}) {
        this.processList = processData;
        this.selectedProcess = this.processList.find((process) => process.process_id === 8); // Set to the desired process ID
      }
    },
    emailValidationRule(value) {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return "Enter the email address";
        }
        // Validate each element in the array
        const isValidEmails = value.every((element) => {
          if (typeof element === "object" && element.email) {
            // If it's an object with an email property
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(element.email);
          } else if (typeof element === "string") {
            // If it's a string
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(element);
          } else {
            // Invalid format
            return false;
          }
        });

        return isValidEmails || "Enter valid email addresses";
      } else if (typeof value === "string") {
        // If it's a single string
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Enter a valid email address";
      } else {
        // Invalid format
        return "Invalid email format";
      }
    },
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },

    async emailList() {
      this.newEmail = "";
      this.emailDropdownList = [];
      let emailData = await trapManagerService.getEmailDropdownData(this.addselectedProject);
      if (emailData.message !== "NA") {
        this.emailDropdownList = emailData;
        this.errorSubProcessMessage = "";
      } else {
        this.errorSubProcessMessage = "Email distribution list is not configured for the selected project, please create distribution list first";
      }
    },

    async emailEditList() {
      this.emailEditDropdownList = [];
      let emailData = await trapManagerService.getEmailDropdownData(this.editselectedProject);
      if (emailData.message !== "NA") {
        this.emailEditDropdownList = emailData;
      }
    },

    projectChange() {
      let businessObj = {
        UserId: this.userId,
        ProjKey: this.addselectedProject,
        BuKey: 0, // Do not Delete
        IncludeEmptyBU: 0, // Do not Delete
        WithinSameLocation: 1, // Do not Delete
        loca_key: 0,
      };
      this.getBusinessData(businessObj, "add");
      this.disableAddBusiness = false;
      this.emailList();
    },

    //Reset Function
    resetFunction() {
      this.selectedBusiness = "";
      this.disableSearch = true;
      this.showFilter = false;
      (this.disableBusiness = true), (this.showData = false);
      this.isFormValid = false;
      this.trapManSearch = "";
      this.selectedProject = 0;
      this.projectList = [];
      this.selectedTrap = "";
      this.trapManList = [];
      this.selectedStatus = "";
      this.totalRecords = 0;
      this.addselectedProject = "";
      this.addselectedTrap = "";
      this.addTrap = "";
      this.newEmail = "";
      this.subProcess = "";
      this.addComments = "";
      this.totalRecords = 0;
      this.trapManageList = [];
      this.trapmanagerShow = false;
      this.searchdisable = false;
      this.projSelec = true;
      this.getProjectDetails();
      this.getTrapTypeDetails();
      this.exportDisable = true;
    },
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Export functionality
    fileName() {
      this.excelName =
        "Trap_Manager_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Getting Project Details
    async getProjectDetails() {
      let projKey = 0;
      let inclEmptyProj = false;
      let projectData = await TrapService.getProject(this.userId, projKey, inclEmptyProj, "get");
      this.projectList = projectData;
    },
    //Getting Trap Type Details
    async getTrapTypeDetails() {
      let trapTypeData = await TrapService.getTrapType("get");
      this.trapManList = trapTypeData;
    },

    onChangeProject() {
      this.disableBusiness = false;
      this.selectedStatus = "";
      this.selectedTrap = [];
      this.trapManageList = [];
      this.totalRecords = 0;
      this.showFilter = false;
      this.disableSearch = true;
      this.trapManSearch = "";
      let businessObj = {
        UserId: this.userId,
        ProjKey: this.selectedProject,
        BuKey: 0, // Do not Delete
        IncludeEmptyBU: 0, // Do not Delete
        WithinSameLocation: 1, // Do not Delete
        loca_key: 0,
      };
      this.getBusinessData(businessObj, "search");
    },
    onChangeBusiness() {
      this.selectedStatus = "";
      this.selectedTrap = [];
      this.trapManageList = [];
      this.totalRecords = 0;
      this.trapManSearch = "";
      this.disableSearch = false;
      this.showFilter = false;
    },

    async getBusinessData(businessObject, loc) {
      if (loc == "search") {
        let businessData = await commonApiService.postBusinessData("post", businessObject);
        this.businessList = businessData.map((x) => ({
          bu_key: x.Bu_id,
          business: x.Business,
          bu_payterm: x.pterms,
        }));
      } else {
        let businessData = await commonApiService.postBusinessData("post", businessObject);
        this.addBusinessList = businessData.map((x) => ({
          bu_key: x.Bu_id,
          business: x.Business,
          bu_payterm: x.pterms,
        }));
      }
    },

    //Search for Trap
    onSearchClick(item) {
      this.showData = true;
      this.TrapSearch();
      this.trapmanagerShow = true;
      if (item == "Project" || item == "status" || item == "trap" || item == "search") this.searchdisable = true;
    },

    async TrapSearch() {
      if (this.selectedStatus != "") {
        this.selectedStatus == "Yes" ? (this.statusselected = "Y") : (this.statusselected = "N");
      } else {
        this.statusselected = "";
      }
      let searchObj = {
        UserID: parseInt(this.userId),
        SearchStr: this.trapManSearch,
        proj_key: this.selectedProject == null ? 0 : parseInt(this.selectedProject),
        list_key: this.selectedTrap.length > 0 ? JSON.stringify(this.selectedTrap) : "[]",
        bu_key: 0,
        IsActive: this.statusselected,
      };
      let searchData = await TrapService.postTrapSearch("post", searchObj);
      this.statusselected = "";
      if (searchData.message != "NA") {
        this.trapManageList = searchData;
        this.showData = true
        this.tempTrapList = this.trapManageList;
        this.showFilter = true;
        this.uniqueTrapType = [...new Map(this.tempTrapList.map((item) => [item["TrapType"], item.TrapType])).values()];
        this.arrayData = [{ id: 1, label: "Trap Type", array: this.uniqueTrapType }];
        this.trapmanagerShow = true;
        this.exportDisable = false;
      } else {
        this.trapManageList = [];
        Utility.showNoDataToast("No Data Available");
      }
      this.totalRecords = this.trapManageList.length;
    },
    async filteredValues(value, type) {
      const filters = {
        0: () => this.tempTrapList,
        1: (value) => this.tempTrapList.filter((element) => element.TrapType === value),
      };

      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.trapManageList = data;
    },
    //Cancel the Search
    onSearchCancel() {
      this.trapManSearch = "";
      this.selectedProject = 0;
      this.selectedTrap = "";
      this.selectedStatus = "";
      this.trapManageList = [];
      this.totalRecords = 0;
      this.trapmanagerShow = false;
      this.searchdisable = false;
      this.exportDisable = true;
    },
    closeDialog() {
      if (this.addTrapDialog) {
        (this.disableAddBusiness = true), (this.addselectedBusiness = "");
        this.addselectedProject = "";
        this.addselectedTrap = "";
        this.addTrap = "";
        this.addComments = "";
        this.newEmail = "";
        this.subProcess = "";
        this.addMaster = true;
        this.$refs.addTrapForm.resetValidation();
        this.addTrapDialog = false;
        this.projSelec = true;
        this.IsActive = true;
        this.errorSubProcessMessage = "";
      } else {
        this.$refs.TrapEditForm.resetValidation();
        this.trapManagerEdit = false;
      }
    },

    async editItem(item) {
      let trapDetailsData = await TrapService.getTrapDetails(this.userId, item.trap_id, "get");

      this.editselectedProject = trapDetailsData[0].proj_key;
      this.editSelectedBusiness = trapDetailsData[0].bu_key;
      this.trapDetailsArray = [
        { label: "Project", value: trapDetailsData[0].Project },
        { label: "Business", value: trapDetailsData[0].business },
        { label: "Hits", value: trapDetailsData[0].Hits },
      ];

      this.trapHistoryList = [];
      this.trapManagerEdit = true;
      if (trapDetailsData.message != "NA") {
        this.emailUpdate = "";
        let editemail = [];

        if (trapDetailsData[0].email && trapDetailsData[0].email.length > 0) {
          editemail = trapDetailsData[0].email.split(";");
        }
        this.Comments = trapDetailsData[0].Comments;
        this.editselectedProject = trapDetailsData[0].proj_key;

        this.emailEditList();
        if (trapDetailsData[0].IsActive == "Yes") {
          this.IsActive = true;
        } else {
          this.IsActive = false;
        }
        this.Hits = trapDetailsData[0].Hits;
        this.editTrap = trapDetailsData[0].Trap;
        this.editselectedTrap = trapDetailsData[0]["Trap_key"];
        (this.allowedit = trapDetailsData[0].allow_edit),
          (this.candelete = trapDetailsData[0].can_delete),
          (this.create_user = trapDetailsData[0].create_user),
          (this.create_date = Utility.convertESTToLocal(trapDetailsData[0].create_date)),
          (this.trap_id = trapDetailsData[0].trap_id);
        this.emailUpdate = editemail;
        if (trapDetailsData[0].Hits === 0) {
          this.disableEmails = true;
          this.disableComments = true;
          this.disableTraps = true;
          this.disableStatus = true;
          this.disableTrap = true;
          this.trapHistory = false;
        }
        if (trapDetailsData[0].Hits > 0) {
          this.trapHistory = true;
          this.disableTraps = false;
          this.disableTrap = false;
          this.disableEmails = true;
          this.disableComments = true;
          this.disableStatus = true;
          this.disableproject = false;
          this.disablehits = false;
        }
        if (trapDetailsData[0].receipts != undefined) {
          const newArr = trapDetailsData[0].receipts.map((obj) => {
            return {
              ...obj,
              Received: Utility.convertESTToLocal(obj.Received),
              AlertSent: Utility.convertESTToLocal(obj["Alert Sent"]),
            };
          });
          this.trapHistoryList = newArr;
        }
      }
    },
    allowEdit() {
      this.allowedEdit = true;
      this.allowedit = true;
    },
    allowDelete() {
      this.deleteTrapDialog = true;
    },
    onClickCancel() {
      this.trapManagerEdit = false;
      this.deleteTrapDialog = false;
      this.$refs.TrapEditForm.resetValidation();
    },
    onDeleteClickCancel() {
      this.deleteTrapDialog = false;
    },
    onClickAddCancel() {
      this.addselectedProject = "";
      this.addselectedTrap = "";
      this.addTrap = "";
      this.addComments = "";
      this.newEmail = "";
      this.subProcess = "";
      this.addTrapDialog = false;
      this.projSelec = true;
      this.addMaster = true;
      this.IsActive = true;
      this.errorSubProcessMessage = "";
      this.$refs.addTrapForm.resetValidation();
    },
    onNewTrapClick() {
      this.IsActive = true;
      this.getProjectDetails();
      this.getTrapTypeDetails();
      this.addTrapDialog = true;
    },
    onTrapHistory() {
      this.trapHistoryDialog = true;
    },
    closeTrapHistoryDialog() {
      this.trapHistoryDialog = false;
    },
    clickAddTrap() {
      if (this.$refs.addTrapForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        if (this.addMaster == false) {
          let emailArray = this.newEmail.map((item) => {
            if (typeof item === "string") {
              return item; // for strings directly in the array
            } else if (item && typeof item === "object" && "email" in item) {
              return item.email; // for objects with an 'email' property
            }
          });
          this.addAPIEmailParam = emailArray.join(";");
        } else {
          let emailArray = this.newEmail.map((item) => {
            if (typeof item === "string") {
              return item; // for strings directly in the array
            } else if (item && typeof item === "object" && "email" in item) {
              return item.email; // for objects with an 'email' property
            }
          });
          this.addAPIEmailParam = emailArray.join(";");
          let emailnewArray = this.newEmail
            .filter((item) => typeof item === "string") // Filter out non-strings
            .map((item) => {
              return item; // for strings directly in the array
            });

          this.addAPINewEmailParam = emailnewArray.join(";");
        }
        let trapData = {
          active: this.IsActive == true ? 1 : 0,
          proj_key: this.addselectedProject,
          bu_key: this.addselectedBusiness,
          list_key: this.addselectedTrap,
          trap: this.addTrap,
          user_key: this.userId,
          reason: this.addComments,
          save_in_email: this.addMaster == true ? 1 : 0,
          email_list: this.addAPIEmailParam,
          newemail_list: this.addAPINewEmailParam,
        };
        {
          this.axios
            .post("/ut/trap_add", trapData)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.body.message,
                };

                this.$store.commit("ConfigModule/Alert", Alert);
                this.$refs.addTrapForm.resetValidation();
                this.closeDialog();
                this.TrapSearch();
                this.addTrapDialog = false;
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };

                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              this.$refs.addTrapForm.resetValidation();
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$refs.addTrapForm.resetValidation();
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },

    onClickUpdate() {
      if (this.$refs.TrapEditForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);

        if (this.editMaster == false) {
          let emailArray = this.emailUpdate.map((item) => {
            if (typeof item === "string") {
              return item; // for strings directly in the array
            } else if (item && typeof item === "object" && "email" in item) {
              return item.email; // for objects with an 'email' property
            }
          });
          this.editAPIEmailParam = emailArray.join(";");
        } else {
          let emailArray = this.emailUpdate.map((item) => {
            if (typeof item === "string") {
              return item; // for strings directly in the array
            } else if (item && typeof item === "object" && "email" in item) {
              return item.email; // for objects with an 'email' property
            }
          });
          this.editAPIEmailParam = emailArray.join(";");
          let emailnewArray = this.emailUpdate
            .filter((item) => typeof item === "string") // Filter out non-strings
            .map((item) => {
              return item; // for strings directly in the array
            });

          this.editAPINewEmailParam = emailnewArray.join(";");
        }

        let trapData = {
          active: this.IsActive == true ? 1 : 0,
          proj_key: this.editselectedProject,
          bu_key: this.editSelectedBusiness,
          list_key: this.editselectedTrap,
          trap: this.editTrap,
          reason: this.Comments,
          trap_key: this.trap_id,
          user_key: this.userId,
          save_in_email: this.editMaster == true ? 1 : 0,
          email_list: this.editAPIEmailParam,
          newemail_list: this.editAPINewEmailParam,
        };
        {
          this.axios
            .post("/ut/trap_update", trapData)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.body.message,
                };
                this.trapManagerEdit = false;
                this.TrapSearch();
                this.$refs.TrapEditForm.resetValidation();
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };

                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
              this.$refs.TrapEditForm.resetValidation();
            });
        }
      }
    },
    deletetrapType() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };

      this.axios
        .get("ut/trap_delete?user_key=" + this.userId + "&trap_key=" + this.trap_id)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.deleteTrapDialog = false;
            this.trapManagerEdit = false;
            this.TrapSearch();
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.deletesSoDialog = false;
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    processcloseDialog() {
      this.addEmailProcessMapperDialog = false;
    },
    //Add button click event
    addEmailProcessMapper() {
      if (this.$refs.addEmailProcessMapperForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        let emailArray = this.newProcessEmail.map((item) => {
          if (typeof item === "string") {
            return item; // for strings directly in the array
          } else if (item && typeof item === "object" && "email" in item) {
            return item.email; // for objects with an 'email' property
          }
        });
        let resultString = emailArray.join(",");
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let obj = {
          process_id: parseInt(this.selectedProcess.process_id),
          proj_id: parseInt(this.addselectedProject),
          sub_process: this.subProcess,
          user_id: parseInt(this.userId),
          email_list: resultString,
          pass: 2,
          mapper_id: 0,
          active: 1,
        };
        this.axios
          .post("em/email_process_upsert", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.addEmailProcessMapperDialog = false;
              this.emailList();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    emailData() {
      this.emailProcessList = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/em/email_dropdown_data`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== null && responseData !== undefined) {
              this.emailProcessList = responseData;
            } else {
              this.emailProcessList = [];
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },

  components: {
    breadcrumbComp,
    DisplayTextData,
    FilterComp,
  },
};
